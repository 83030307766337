$(document).ready(function () {
	var viewport_width = $(window).width();
	if (viewport_width <= 900) {
		$('#menuBtn').click(function () {
			$('.leftAsideBar').removeClass("closebtn");
			$('.leftAsideBar').addClass("toggled mobile_nav");

		});
		$('#closebtn').click(function () {
			$('.leftAsideBar').addClass("closebtn");
			$('.leftAsideBar').removeClass("toggled mobile_nav");

		});
	}
	else {
		$('.leftAsideBar').removeClass("closebtn");
		$('.leftAsideBar').removeClass("toggled mobile_nav");
	}
});

$(window).resize(function () {
	var viewport_width = $(window).width();
	if (viewport_width <= 900) {
		$('#menuBtn').click(function () {
			$('.leftAsideBar').removeClass("closebtn");
			$('.leftAsideBar').addClass("toggled mobile_nav");
		});

		$('#closebtn').click(function () {
			$('.leftAsideBar').addClass("closebtn");
			$('.leftAsideBar').removeClass("toggled mobile_nav");

		});

		$('.rotationArrow ').click(function () {
			$('.collapse').addClass("show");

		});
	}
	else {
		$('.leftAsideBar').removeClass("closebtn");
		$('.leftAsideBar').removeClass("toggled mobile_nav");
	}
});


function hoverButtons(event, state, edit = true, copy = true, remove = true) {
	if (state) {
		var div = document.createElement("div");
		const editHtml = edit ? `<a><span class="icon-edit1"></span></a>` : ``;
		const copyHtml = copy ? `<a><span class="icon-copy"></span></a>` : ``;
		const removeHtml = remove ? `<a><span class="icon-delete"></span></a>` : ``;
		div.innerHTML = `
    <div class="topBar">
        <div class="right-ic">` +
			editHtml +
			copyHtml +
			removeHtml + `
        </div>
    </div>`;

		div.id = "headerLogo";
		div.classList.add("headerLogo");
		event.appendChild(div);
	}
	else {
		var elem = document.querySelector('#headerLogo');
		elem.parentNode.removeChild(elem);
	}
}

$(document).click(function (event) {
	const timeout = 100;
	let isEditorOpen;

	if (event.target.closest('.rightSidetabs')) {
		isEditorOpen = event.target.closest('.rightSidetabs').classList.contains('rightSidetabs');
	}

	setTimeout(() => {
		if (event.target.classList.contains('leftslider-arrow') ||
			event.target.classList.contains('stopclick') ||
			event.target.classList.contains('fa-angle-right')
		) {
			$('.rightBody').addClass('removeleftSlider');
			$('.leftslider-arrow').css('visibility', 'hidden');
		}
		else {
			$('.rightBody').removeClass('removeleftSlider');
			$('.leftslider-arrow').css('visibility', 'visible');
		}

		if (event.target.classList.contains('icon-edit1') ||
			event.target.classList.contains('icon-edit') ||
			event.target.classList.contains('fa-angle-left') ||
			isEditorOpen
		) {
			$('.rightSlider').addClass('addrightSlider');
		}
		else {
		}

	}, timeout);
});

CKEDITOR_BASEPATH = '/assets/js/ckeditor/';
