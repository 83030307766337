(function (factory) {
  "use strict";

  if (typeof define === 'function' && define.amd) { // AMD
    define(['jquery'], factory);
  }
  else if (typeof exports == "object" && typeof module == "object") { // CommonJS
    module.exports = factory(require('jquery'));
  }
  else { // Browser
    factory(jQuery);
  }
})(function($, undefined) {
  "use strict";

  var defaultOpts = {
    beforeShow: noop,
    change: noop,
    show: noop,
    hide: noop,

    appendTo: "body",
    cancelText: "cancel",
    chooseText: "apply",
    disabled: false,
    offset: null,
    elements: ['margin', 'padding', 'height', 'width', 'margin-top', 'margin-left', 'margin-bottom', 'margin-right',
      'padding-top', 'padding-left', 'padding-bottom', 'padding-right', 'background-size', 'aspect-ratio', 'background-repeat'],
  },
  settingsPopups = [],
  markup = (function () {
    return [
      "<div class='es-container es-hidden'>",
      "<div class='es-input-container'>",
      "</div>",
      "<div class='es-button-container'>",
      "<button type='button' class='es-cancel'></button>",
      "<button type='button' class='es-choose'></button>",
      "</div>",
      "</div>"
    ].join('');
  })();

  function instanceOptions(o, callbackContext) {
    var opts = $.extend({}, defaultOpts, o);
    opts.callbacks = {
      'change': bind(opts.change, callbackContext),
      'show': bind(opts.show, callbackContext),
      'hide': bind(opts.hide, callbackContext),
      'beforeShow': bind(opts.beforeShow, callbackContext)
    };

    return opts;
  }

  function hideAll() {
    for (var i = 0; i < settingsPopups.length; i++) {
      if (settingsPopups[i]) {
        settingsPopups[i].hide();
      }
    }
  }

  function editorSettings(element, o) {
    var opts = instanceOptions(o, element),
    callbacks = opts.callbacks,
    resize = throttle(reflow, 10),
    visible = false,
    activeBlock = null;

    var doc = element.ownerDocument,
      boundElement = $(element),
      disabled = false,
      container = $(markup, doc),
      inputContainer = container.find(".es-input-container"),
      cancelButton = container.find(".es-cancel"),
      chooseButton = container.find(".es-choose"),
      clickoutFiresChange = opts.clickoutFiresChange,
      offsetElement = boundElement,
      elements = opts.elements;

    var thisId = boundElement.attr('id');
    if(thisId !== undefined && thisId.length > 0) {
      var label = $('label[for="'+thisId+'"]');
      if(label.length) {
        label.on('click', function(e){
          e.preventDefault();
          boundElement.editorSettings('show');
          return false;
        });
      }
    }

    function destroy() {
      boundElement.show().removeClass('editorSettings with-add-on sp-colorize');
      offsetElement.off("click.editorSettings touchstart.editorSettings");
      container.remove();
      var originalInputContainer = boundElement.closest('.sp-original-input-container');
      if (originalInputContainer.length > 0) {
        originalInputContainer.after(boundElement).remove();
      }
      settingsPopups[esPopup.id] = null;
    }

    function reflow() {
      if (!visible) {
        return; // Calculations would be useless and wouldn't be reliable anyways
      }
      container.css("position", "absolute");
      if (opts.offset) {
        container.offset(opts.offset);
      } else {
        container.offset(getOffset(container, offsetElement));
      }
      boundElement.trigger('reflow.editorSettings');
    }

    function show() {
      // debugger;
      var event = $.Event('beforeShow.editorSettings');

      if (visible) {
        reflow();
        return;
      }

      boundElement.trigger(event, [ get() ]);

      if (callbacks.beforeShow(get()) === false || event.isDefaultPrevented()) {
        return;
      }

      hideAll();
      visible = true;

      $(doc).on("click.editorSettings", clickout);
      $(window).on("resize.editorSettings", resize);
      container.removeClass("es-hidden");

      reflow();
      boundElement.trigger('show.spectrum', [ null ]);
    }

    function clickout(e) {
      // Return on right click.
      if (e.button == 2) { return; }

      if (clickoutFiresChange) {
        boundElement.trigger('change', [ get() ]);
        callbacks.change(get(), opts.elements);
      }
      hide();
    }

    function initialize() {
      var appendTo = opts.appendTo === "parent" ? boundElement.parent() : $(opts.appendTo);
      if (appendTo.length !== 1) {
        appendTo = $("body");
      }
      appendTo.append(container);

      reflow();

      offsetElement.on("click.editorSettings touchstart.editorSettings", function (e) {
        if (!disabled) {
          show();
        }
        e.stopPropagation();
      });

      if(boundElement.is(":disabled") || (opts.disabled === true)) {
        disable();
      }

      let html = [];
      let input = '';
      elements.forEach(item => {
        let displayName = item.replace('-', ' ');
        if(item === 'background-size') {
          input += `<div class="es-input-control">
                        <label class="es-label">${displayName}</label>
                        <div class="es-input-group">
                            <select name="${item}" class="es-input" style='background-color: white'>
                              <option value='' selected='selected'>select</option>
                              <option value='cover'>cover</option>
                              <option value='contain'>contain</option>
                            </select>
                        </div></div>`;
        } else if(item === 'background-repeat') {
          input += `<div class="es-input-control" style='margin-left: 24px '>
                        <label class="es-label">${displayName}</label>
                        <div class="es-input-group">
                            <select name="${item}" class="es-input" style='background-color: white'>
                              <option value='' selected='selected'>select</option>
                              <option value='repeat'>repeat</option>
                              <option value='repeat-x'>repeat-x</option>
                              <option value='repeat-y'>repeat-y</option>
                            </select>
                        </div></div>`;
        } else if (item === 'aspect-ratio') {
          input += `<div class='es-input-control'>
                        <label class='es-label'>${displayName}</label>
                       <div class='es-input-group'>
                            <input type='number' name='${item}' class='es-input' min='1' step='0.1' placeholder='${displayName}'/>
                        </div></div>`;
        } else {
          input += `<div class='es-input-control'>
                        <label class='es-label'>${displayName}</label>
                        <div class='es-input-group'>
                            <input type='text' name='${item}' class='es-input' placeholder='${displayName}'/><span class='es-input-post-fix'>px</span>
                        </div></div>`;
        }
      })
      html.push(input);

      inputContainer.append(html.join(''));

      // Prevent clicks from bubbling up to document.  This would cause it to be hidden.
      container.click(stopPropagation);

      cancelButton.text(opts.cancelText);
      cancelButton.on("click.editorSettings", function (e) {
        e.stopPropagation();
        e.preventDefault();
        hide();
      });

      chooseButton.text(opts.chooseText);
      chooseButton.on("click.editorSettings", function (e) {
        e.stopPropagation();
        e.preventDefault()
        boundElement.trigger('change', [ get() ]);
        callbacks.change(get(), opts.elements);
        hide();
      });
    }

    function hide() {
      // Return if hiding is unnecessary
      if (!visible) { return; }
      visible = false;

      $(doc).off("click.editorSettings", clickout);
      $(window).off("resize.editorSettings", resize);
      container.addClass("es-hidden");

      callbacks.hide(get());
      boundElement.trigger('hide.editorSettings', [ get() ]);
    }

    function enable() {
      disabled = false;
      boundElement.attr("disabled", false);
      offsetElement.removeClass("es-disabled");
    }

    function disable() {
      hide();
      disabled = true;
      boundElement.attr("disabled", true);
      offsetElement.addClass("es-disabled");
    }

    function updateBlockValue(block) {
      if (activeBlock == block) return;
      activeBlock = block;
      opts.elements.forEach(item => {
        let value = activeBlock.el.style[item];
        if (item === 'aspect-ratio') {
          value = value.substring(0, value.indexOf(' '));
        }
        if (item === 'background-size' || item === 'background-repeat') {
          let inputBox = container.find(`.es-input-control .es-input-group select[name="${item}"]`)
          if (inputBox) {
            if (item === 'background-size' && value === 'unset') {
              value = 'cover'
            }
            inputBox.val((value || ''));
          }
        }
        else {
          let inputBox = container.find(`.es-input-control .es-input-group input[name="${item}"]`)
          if (inputBox) {
            inputBox.val((value || '').replace('px', ''));
          }
        }
      })
    }

    function get() {
      let returnValue = {};
      opts.elements.forEach(item => {
        if (item === 'background-size' || item === 'background-repeat') {
          let inputBox = container.find(`.es-input-control .es-input-group select[name="${item}"]`);
          if (inputBox) {
            returnValue[item] = (inputBox.val());
          }
        }
        else{
          let inputBox = container.find(`.es-input-control .es-input-group input[name="${item}"]`)
          if (inputBox) {
            returnValue[item] = (inputBox.val()).replace('px', '');
          }
        }
      })
      return returnValue;
    }

    initialize();

    var esPopup = {
      show: show,
      hide: hide,
      reflow: reflow,
      enable: enable,
      disable: disable,
      get: get,
      destroy: destroy,
      updateBlockValue: updateBlockValue,
      container: container
    };

    esPopup.id = settingsPopups.push(esPopup) - 1;

    return esPopup;
  }

  function bind(func, obj) {
    var slice = Array.prototype.slice;
    var args = slice.call(arguments, 2);
    return function () {
      return func.apply(obj, args.concat(slice.call(arguments)));
    };
  }

  function throttle(func, wait, debounce) {
    var timeout;
    return function () {
      var context = this, args = arguments;
      var throttler = function () {
        timeout = null;
        func.apply(context, args);
      };
      if (debounce) clearTimeout(timeout);
      if (debounce || !timeout) timeout = setTimeout(throttler, wait);
    };
  }

  /**
   * noop - do nothing
   */
  function noop() {

  }

  /**
   * stopPropagation - makes the code only doing this a little easier to read in line
   */
  function stopPropagation(e) {
    e.stopPropagation();
  }

  function getOffset(picker, input) {
    var extraY = 0;
    var dpWidth = picker.outerWidth();
    var dpHeight = picker.outerHeight();
    var inputHeight = input.outerHeight();
    var doc = picker[0].ownerDocument;
    var docElem = doc.documentElement;
    var viewWidth = docElem.clientWidth + $(doc).scrollLeft();
    var viewHeight = docElem.clientHeight + $(doc).scrollTop();
    var offset = input.offset();
    var offsetLeft = offset.left;
    var offsetTop = offset.top;

    offsetTop += inputHeight;

    offsetLeft -=
      Math.min(offsetLeft, (offsetLeft + dpWidth > viewWidth && viewWidth > dpWidth) ?
        Math.abs(offsetLeft + dpWidth - viewWidth) : 0);

    offsetTop -=
      Math.min(offsetTop, ((offsetTop + dpHeight > viewHeight && viewHeight > dpHeight) ?
        Math.abs(dpHeight + inputHeight - extraY) : extraY));

    return {
      top: offsetTop,
      bottom: offset.bottom,
      left: offsetLeft,
      right: offset.right,
      width: offset.width,
      height: offset.height
    };
  }

  var dataID = "editorSettings.id";
  $.fn.editorSettings = function (opts) {
    if (typeof opts == "string") {

      var returnValue = this;
      var args = Array.prototype.slice.call( arguments, 1 );

      this.each(function () {
        var esPopup = settingsPopups[$(this).data(dataID)];
        if (esPopup) {
          var method = esPopup[opts];
          if (!method) {
            throw new Error( "Editor Settings: no such method: '" + opts + "'" );
          }
          if (opts == "destroy") {
            esPopup.destroy();
            $(this).removeData(dataID);
          }
          else {
            method.apply(esPopup, args);
          }
        }
      });

      return returnValue;
    }

    return this.editorSettings("destroy").each(function () {
      var options = $.extend({}, $(this).data(), opts);
      var esPopup = editorSettings(this, options);
      $(this).data(dataID, esPopup.id);
    });
  }
});
